import React from 'react'

import { useNavigate } from '@arch-log/webapp.lib/react.hook'
import { useProjectRoutes } from 'components/ProjectRoutes'

import * as ProjectAuthorization from '@arch-log/webapp.modules/project.authorization.v2/components'
import * as Permissions from '@arch-log/webapp.modules/project.authorization.v2/permissions'
import * as ProjectDestination from '@arch-log/webapp.modules/project.destination.v2/components'
import * as NoPermission from '@arch-log/webapp.shared/Errors/NoPermission'
import * as PageHeadTemplates from '@arch-log/webapp.shared/Page/Head'
import * as Partial from 'partials/AddressNewPage'

/**
 */
export const Head = ({ pageContext }) => {
  return <PageHeadTemplates.Language language={pageContext?.language} />
}

/**
 */
const Page = ({ params: { language, id } }) => {
  const hasPermission = ProjectAuthorization.Remote.Queries.MyEntry.useHasPermissions()
  const { redirectTo } = useNavigate()

  const routes = useProjectRoutes()

  if (!hasPermission(Permissions.DestinationsWrite)) {
    return <NoPermission.Default />
  }

  return (
    <ProjectDestination.Remote.Queries.Entries.Loader projectId={id}>
      <ProjectDestination.Remote.Mutations.CreateEntry.Initializer>
        <Partial.Flow
          projectId={id}
          onComplete={() => redirectTo(routes.toAddressList())}
        />
      </ProjectDestination.Remote.Mutations.CreateEntry.Initializer>
    </ProjectDestination.Remote.Queries.Entries.Loader>
  )
}

export default Page
