import React from 'react'

import * as Raw from '@arch-log/webapp.shared/AppDefinitions/Project/Routes'

/**
 */
export class ProjectRoutes {
  constructor(project, opts = {}) {
    this.project = project
    this.opts = opts
  }

  toProjectList = (opts = {}) => Raw.toProjectList({ ...this.opts, ...opts })

  toProductList = (opts = {}) =>
    Raw.toProducts({ project: this.project, ...this.opts, ...opts })

  toSampleRequests = (opts = {}) =>
    Raw.toSampleRequests({ project: this.project, ...this.opts, ...opts })

  toAddressList = (opts = {}) =>
    Raw.toAddressList({ project: this.project, ...this.opts, ...opts })

  toSetting = (opts = {}) =>
    Raw.toSetting({ project: this.project, ...this.opts, ...opts })

  toFt = (opts = {}) =>
    Raw.toFt({ project: this.project, ...this.opts, ...opts })
}
