import React from 'react'

import { Context } from './Context'
import { useSetState } from 'react-use'

/**
 */
export const Initializer = ({
  context: defaultContext = {},
  children,
  steps,
  onComplete,
}) => {
  const [context, setContext] = useSetState(defaultContext)

  const [state, setState] = React.useState({ steps, step: 0 })

  const { step } = state

  const prev = (data = {}) => {
    setContext(data)
    setState({ ...state, step: step > 0 ? step - 1 : 0 })
  }

  const next = (data = {}) => {
    setContext(data)
    const nextStep = step + 1

    if (nextStep >= steps.length) {
      if (onComplete) {
        onComplete(context)
      }
    } else {
      setState({ ...state, step: nextStep })
    }
  }

  const reset = () => {
    setState({ ...state, step: 0 })
  }

  return (
    <Context.Provider
      value={{ context, steps, step, next, prev, reset, setContext }}
    >
      {children}
    </Context.Provider>
  )
}

/**
 */
export const CurrentStep = (props) => {
  const { steps, step, next, prev, reset, context, setContext } =
    React.useContext(Context)

  const Component = steps[step]

  if (!Component) {
    console.error(`Component is not specified for step: ${step}`)
    return null
  }

  return (
    <Component
      next={next}
      prev={prev}
      reset={reset}
      {...props}
      context={context}
      setContext={setContext}
    />
  )
}
