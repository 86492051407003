import React from 'react'

import { createFlow } from '@arch-log/webapp.atomics/components/Flow'
import { useAsync } from 'react-use'

import * as Routes from '@arch-log/webapp.shared/AppDefinitions/Project/Routes'
import * as ProjectDestination from '@arch-log/webapp.modules/project.destination.v2/components'
import * as Notices from '@arch-log/webapp.shared/Notices'
import * as Containers from './Containers'
import * as Layout from './Layout'

/**
 */
export const Init = ({ next }) => {
  React.useEffect(() => {
    next({ defaultValues: {} })
  }, [])

  return null
}

/**
 */
export const Input = ({ context, next }) => {
  return (
    <Containers.FormInitializer
      onSubmit={(data) => next({ formData: data })}
      defaultValues={context?.defaultValues}
      values={context?.formData}
      errors={context?.errors}
    >
      <Notices.Default />
      <Layout.Wrapper>
        <Layout.Header>
          <Containers.Title />
        </Layout.Header>
        <Layout.Body>
          <Containers.InputRequiredLabel />
          <Containers.Errors />
          <Containers.FormFields />
          <Containers.Buttons />
        </Layout.Body>
        <Layout.Footer></Layout.Footer>
      </Layout.Wrapper>
    </Containers.FormInitializer>
  )
}

/**
 */
export const ProgressStep = ({ projectId, context, next, prev }) => {
  const [
    createEntry,
  ] = ProjectDestination.Remote.Mutations.CreateEntry.useMutation()

  useAsync(async () => {
    try {
      await createEntry({
        variables: {
          projectId,
          data: ProjectDestination.Form.Helper.formToModel(context?.formData),
        },
        onCompleted: (data) => {
          next()
        },
        onError: (error) => {
          switch (error.message) {
            default:
              prev({ errors: { _form: 'Form.Error.Failed' } })
          }
        },
      })
    } catch (error) {
      prev({ errors: { _form: 'Form.Error.Failed' } })
    }
  }, [])

  return <Containers.Progress />
}

/**
 */
const RefetchStep = ({ projectId, next }) => {
  const { refetch } = ProjectDestination.Remote.Queries.Entries.useContext()

  const [error, setError] = React.useState(null)

  React.useEffect(() => {
    if (error) {
      window.location.href = Routes.toAddressList({
        project: projectId,
      })
    }
  }, [error])

  useAsync(async () => {
    try {
      await refetch()
      next()
    } catch (error) {
      setError(error)
    }
  }, [])

  return <Containers.Progress />
}

/**
 */
const BaseSubFlow = createFlow([ProgressStep, RefetchStep])

/**
 */
export const Progress = ({ projectId, next, prev, context }) => {
  return (
    <Layout.Wrapper>
      <Layout.Body>
        <BaseSubFlow
          projectId={projectId}
          context={context}
          onComplete={() => {
            next()
          }}
          prev={prev}
        />
      </Layout.Body>
    </Layout.Wrapper>
  )
}

/**
 */
export const Complete = ({ next }) => {
  React.useEffect(() => {
    next()
  }, [])

  return null
}
