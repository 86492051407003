import React from 'react'

import { withDefaultRenderComponent } from '@arch-log/webapp.atomics/components/Rules'

import * as Containers from './Containers'

/**
 * Default Container
 */
export const Default = withDefaultRenderComponent(
  ({
    components: { Notices: noticesComponents, Notice: noticeComponents } = {},
  }) => {
    return (
      <Containers.Notices components={noticesComponents}>
        <Containers.Notice components={noticeComponents} />
      </Containers.Notices>
    )
  },
)
