import React from 'react'

import * as Components from './Components'
import * as ControllerComponents from './Controller/index.js'

/**
 */
export const createFlow = (steps = [], propsOverride = {}) => {
  const Flow = (props) => {
    return (
      <ControllerComponents.Draw>
        <Components.Initializer
          {...{ ...props, ...propsOverride }}
          steps={steps}
        >
          <Components.CurrentStep {...{ ...props, ...propsOverride }} />
        </Components.Initializer>
      </ControllerComponents.Draw>
    )
  }

  Flow.Controller = (props) => {
    return <ControllerComponents.Initializer {...props} />
  }

  Flow.Controller.useController = ControllerComponents.useController

  return Flow
}
