import React from 'react'

import { createFlow } from '@arch-log/webapp.atomics/components/Flow'

import * as Steps from './Steps'

/**
 */
export const BaseFlow = createFlow([
  Steps.Init,
  Steps.Input,
  Steps.Progress,
  Steps.Complete,
])

export const Flow = ({ projectId, ...props }) => {
  return <BaseFlow projectId={projectId} {...props} />
}
