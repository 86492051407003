import React from 'react'

import { withDefaultRenderComponent } from '@arch-log/webapp.atomics/components/Rules'

import * as Module from '@arch-log/webapp.modules/notifier/components/Notice'
import * as Styled from './Styled'

/**
 * Notice Container
 */
export const Notice = ({
  components: {
    Wrapper = Styled.NoticeWrapper,
    Remove = Styled.NoticeRemove,
    Message = Styled.NoticeMessage,
  } = {},
}) => {
  const notice = Module.useNotice()
  const { remove } = Module.useActions()

  if (!notice) {
    return null
  }

  return (
    <Wrapper>
      <Remove
        isVisible={notice.isRemovable}
        onRemove={() => {
          remove()
        }}
      />
      <Message>
        <Module.Properties.Message />
      </Message>
    </Wrapper>
  )
}
