import React from 'react'

import { Context } from './Context.jsx'

/**
 *
 */
export const Initializer = ({ started: defaultStarted = false, children }) => {
  const [started, setStarted] = React.useState(defaultStarted)

  const { start, stop } = React.useMemo(
    () => ({
      start: () => {
        setStarted(true)
      },
      stop: () => {
        setStarted(false)
      },
    }),
    [setStarted],
  )

  return (
    <Context.Provider value={{ started, setStarted, start, stop }}>
      {children}
    </Context.Provider>
  )
}

export const Draw = ({ children }) => {
  const { started } = React.useContext(Context)

  if (!started) {
    return null
  }

  return <>{children}</>
}
