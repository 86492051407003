import React from 'react'

import { Context } from './Context.jsx'

/**
 */
export const useController = () => {
  const { started, start, stop } = React.useContext(Context)

  return [started, { start, stop }]
}
