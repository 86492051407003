import React from 'react'

import * as ProjectContentLayout from 'components/ProjectContentLayout'
import * as styles from './styles.module.scss'

/**
 */
export const Wrapper = ({ children }) => (
  <ProjectContentLayout.Container>
    <div className={styles.Wrapper}>{children}</div>
  </ProjectContentLayout.Container>
)

/**
 */
export const Header = ({ children }) => (
  <ProjectContentLayout.Header>
    <div className={styles.Header}>{children}</div>
  </ProjectContentLayout.Header>
)

/**
 */
export const Body = ({ children }) => (
  <ProjectContentLayout.Body>
    <div className={styles.Body}>{children}</div>
  </ProjectContentLayout.Body>
)

/**
 */
export const Footer = ({ children }) => <>{children}</>
