import React from 'react'

import { Page as PageLoader } from '@arch-log/webapp.atomics/components/Loader'

import * as CreateForm from '@arch-log/webapp.modules/project.destination.v2/components/Form'
import * as ProjectLinks from '@arch-log/webapp.shared/Project.v2/Links'
import * as FormStyles from '@arch-log/webapp.atomics/components/Form/Style'
import * as FormErrorsStyles from '@arch-log/webapp.atomics/components/Form/Errors/Style'
import * as FormErrors from '@arch-log/webapp.atomics/components/Form/Errors'
import * as Styled from './Styled'
import * as Literals from './Literals'
import * as Fields from './Fields'
import * as styles from './styles.module.scss'
import * as formStyles from './form.module.scss'
import * as errorStyles from './error.module.scss'

/**
 */
export const FormInitializer = ({
  children,
  onSubmit,
  defaultValues,
  values,
  errors,
}) => {
  return (
    <FormStyles.Provider styles={formStyles}>
      <FormErrorsStyles.Provider styles={errorStyles}>
        <CreateForm.Initializer
          onSubmit={onSubmit}
          defaultValues={defaultValues}
          values={values}
          errors={errors}
        >
          {children}
        </CreateForm.Initializer>
      </FormErrorsStyles.Provider>
    </FormStyles.Provider>
  )
}

/**
 */
export const Title = () => {
  return (
    <Styled.Title>
      <Literals.Title />
    </Styled.Title>
  )
}

/**
 */
export const InputRequiredLabel = () => {
  return (
    <Styled.InputRequiredLabel>
      <Literals.RequiredLabel />
    </Styled.InputRequiredLabel>
  )
}

/**
 */
const Back = () => {
  return (
    <ProjectLinks.ToAddressList className={styles.BackButton}>
      <Literals.Cancel />
    </ProjectLinks.ToAddressList>
  )
}

/**
 */
const Submit = () => (
  <Styled.SubmitButton>
    <Literals.Submit />
  </Styled.SubmitButton>
)

/**
 */
export const Buttons = () => (
  <Styled.ButtonContainer>
    <Submit />
    <Back />
  </Styled.ButtonContainer>
)

export const FormFields = () => (
  <>
    <Fields.CompanyName />
    <Fields.DepartmentName />
    <Fields.OperatorName />
    <Fields.OperatorPhoneticName />
    <Fields.PostalCode />
    <Fields.StateOrPrefecture />
    <Fields.City />
    <Fields.Street />
    <Fields.Building />
    <Fields.PhoneNumber />
  </>
)

/**
 */
export const Errors = () => {
  const { formatFormError } = Literals.useLiterals()

  return (
    <CreateForm.Errors.Form
      className={errorStyles.Errors}
      formatMessage={formatFormError}
    />
  )
}

/**
 */
export const Progress = () => {
  return (
    <PageLoader>
      <Literals.Progress />
    </PageLoader>
  )
}
