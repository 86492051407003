import React from 'react'

import * as Project from '@arch-log/webapp.modules/project.v2/components'
import * as Languages from '@arch-log/webapp.modules/i18n/components/Languages'
import { ProjectRoutes } from './ProjectRoutes'

/**
 */
export const useProjectRoutes = () => {
  const { language } = React.useContext(Languages.Context)

  const [entry] = Project.Scope.Entry.useEntry()
  const [routes, setRoutes] = React.useState(null)

  React.useEffect(() => {
    setRoutes(new ProjectRoutes(entry.id, { language }))
  }, [entry])

  return routes
}
