// extracted by mini-css-extract-plugin
export var AddressPostalCode = "styles-module--AddressPostalCode--87W7D";
export var BackButton = "styles-module--BackButton--hddSJ";
export var Body = "styles-module--Body--rbLLr";
export var ButtonContainer = "styles-module--ButtonContainer--0ngkn";
export var FieldContainer = "styles-module--FieldContainer--KqB+2";
export var FieldInputItem = "styles-module--FieldInputItem--fqObt";
export var FieldLabel = "styles-module--FieldLabel--JDa7r";
export var Header = "styles-module--Header--fCoM4";
export var InputRequiredLabel = "styles-module--InputRequiredLabel--BUsKw";
export var OperatorName = "styles-module--OperatorName--Iel2-";
export var OperatorName_Item = "styles-module--OperatorName_Item--lbfEf";
export var PhoneNumberContainer = "styles-module--PhoneNumberContainer--sgY6C";
export var PhoneNumber_Suffix = "styles-module--PhoneNumber_Suffix--rk9Pi";
export var RequiredIcon = "styles-module--RequiredIcon--oy8r3";
export var ResolveButton = "styles-module--ResolveButton--LaawL";
export var SubmitButton = "styles-module--SubmitButton--WrcEp";
export var Title = "styles-module--Title--cfpZH";
export var Wrapper = "styles-module--Wrapper--pUjR6";