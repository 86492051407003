import React from 'react'

import * as CreateForm from '@arch-log/webapp.modules/project.destination.v2/components/Form'
import * as AddressRemote from '@arch-log/webapp.modules/address.v2/components/Remote'
import * as Styled from './Styled'
import * as Literals from './Literals'

import * as styles from './styles.module.scss'

/**
 */
const withFieldComponents = (fieldName, Component, required = true) => ({
  components: {
    Container = Styled.FieldContainer,
    Label = Styled.FieldLabel,
    Input = Styled.FieldInput,
  } = {},
  ...props
}) => {
  const { formatFieldLabel } = Literals.useLiterals()

  return (
    <Container>
      <Label>
        {formatFieldLabel(fieldName)}
        <Styled.RequiredIcon required={required} />
      </Label>
      <Input>
        <Component {...props} />
      </Input>
    </Container>
  )
}

/**
 */
export const CompanyName = withFieldComponents('CompanyName', () => {
  const { formatPlaceholder, formatFieldError } = Literals.useLiterals()

  return (
    <>
      <CreateForm.Errors.CompanyName formatMessage={formatFieldError} />
      <CreateForm.Fields.CompanyName
        className={styles.FieldInputItem}
        formatPlaceholder={formatPlaceholder}
      />
    </>
  )
})

/**
 */
export const DepartmentName = withFieldComponents(
  'DepartmentName',
  () => {
    const { formatPlaceholder, formatFieldError } = Literals.useLiterals()

    return (
      <>
        <CreateForm.Errors.DepartmentName formatMessage={formatFieldError} />
        <CreateForm.Fields.DepartmentName
          className={styles.FieldInputItem}
          formatPlaceholder={formatPlaceholder}
        />
      </>
    )
  },
  false,
)

/**
 */
export const OperatorName = withFieldComponents('OperatorName', () => {
  const { formatPlaceholder, formatFieldError } = Literals.useLiterals()

  return (
    <div className={styles.OperatorName}>
      <CreateForm.Errors.OperatorLastName formatMessage={formatFieldError} />
      <CreateForm.Fields.OperatorLastName
        className={styles.OperatorName_Item}
        formatPlaceholder={formatPlaceholder}
      />
      <CreateForm.Errors.OperatorFirstName formatMessage={formatFieldError} />
      <CreateForm.Fields.OperatorFirstName
        className={styles.OperatorName_Item}
        formatPlaceholder={formatPlaceholder}
      />
    </div>
  )
})

/**
 */
export const OperatorPhoneticName = withFieldComponents(
  'OperatorPhoneticName',
  () => {
    const { formatPlaceholder, formatFieldError } = Literals.useLiterals()

    return (
      <div className={styles.OperatorName}>
        <CreateForm.Errors.OperatorPhoneticLastName
          formatMessage={formatFieldError}
        />
        <CreateForm.Fields.OperatorPhoneticLastName
          className={styles.OperatorName_Item}
          formatPlaceholder={formatPlaceholder}
        />
        <CreateForm.Errors.OperatorPhoneticFirstName
          formatMessage={formatFieldError}
        />
        <CreateForm.Fields.OperatorPhoneticFirstName
          className={styles.OperatorName_Item}
          formatPlaceholder={formatPlaceholder}
        />
      </div>
    )
  },
)

/**
 */
export const PostalCode = withFieldComponents('PostalCode', () => {
  const {
    formatPlaceholder,
    formatFieldError,
    formatActionError,
  } = Literals.useLiterals()

  return (
    <AddressRemote.Queries.Entry.LazyLoader>
      <div className={styles.AddressPostalCode}>
        <CreateForm.Errors.PostalCodeUpper formatMessage={formatFieldError} />
        <CreateForm.Errors.PostalCodeLower formatMessage={formatFieldError} />
        <CreateForm.Fields.PostalCodeUpper
          formatPlaceholder={formatPlaceholder}
          className={styles.FieldInputItem}
          required={true}
        />
        <Styled.PostalCodeSeparator />
        <CreateForm.Fields.PostalCodeLower
          formatPlaceholder={formatPlaceholder}
          className={styles.FieldInputItem}
          required={true}
        />
        <CreateForm.ResolveAddress
          render={({ onClick }) => (
            <span className={styles.ResolveButton} onClick={() => onClick()}>
              <Literals.ResolveAddress />
            </span>
          )}
          className={styles.ResolveButton}
          formatResolveError={formatActionError}
        />
      </div>
    </AddressRemote.Queries.Entry.LazyLoader>
  )
})

/**
 */
export const StateOrPrefecture = withFieldComponents(
  'StateOrPrefecture',
  () => {
    const { formatPlaceholder, formatFieldError } = Literals.useLiterals()

    return (
      <>
        <CreateForm.Errors.StateOrPrefecture formatMessage={formatFieldError} />
        <CreateForm.Fields.StateOrPrefecture
          className={styles.FieldInputItem}
          formatPlaceholder={formatPlaceholder}
          required={true}
        />
      </>
    )
  },
)

/**
 */
export const City = withFieldComponents('City', () => {
  const { formatPlaceholder, formatFieldError } = Literals.useLiterals()

  return (
    <>
      <CreateForm.Errors.City formatMessage={formatFieldError} />
      <CreateForm.Fields.City
        className={styles.FieldInputItem}
        formatPlaceholder={formatPlaceholder}
        required={true}
      />
    </>
  )
})

/**
 */
export const Street = withFieldComponents('Street', () => {
  const { formatPlaceholder, formatFieldError } = Literals.useLiterals()

  return (
    <>
      <CreateForm.Errors.City formatMessage={formatFieldError} />
      <CreateForm.Fields.Street
        className={styles.FieldInputItem}
        formatPlaceholder={formatPlaceholder}
      />
    </>
  )
})

/**
 */
export const Building = withFieldComponents(
  'Building',
  () => {
    const { formatPlaceholder } = Literals.useLiterals()

    return (
      <CreateForm.Fields.Building
        className={styles.FieldInputItem}
        formatPlaceholder={formatPlaceholder}
      />
    )
  },
  false,
)

/**
 */
export const PhoneNumber = withFieldComponents('PhoneNumber', () => {
  const {
    formatPlaceholder,
    formatSuffix,
    formatFieldError,
  } = Literals.useLiterals()

  return (
    <Styled.PhoneNumberContainer>
      <CreateForm.Errors.PhoneNumberFirst formatMessage={formatFieldError} />
      <CreateForm.Errors.PhoneNumberSecond formatMessage={formatFieldError} />
      <CreateForm.Errors.PhoneNumberThird formatMessage={formatFieldError} />
      <CreateForm.Fields.PhoneNumberFirst
        formatPlaceholder={formatPlaceholder}
        className={styles.FieldInputItem}
      />
      <Styled.PhoneNumberSeparator />
      <CreateForm.Fields.PhoneNumberSecond
        formatPlaceholder={formatPlaceholder}
        className={styles.FieldInputItem}
      />
      <Styled.PhoneNumberSeparator />

      <CreateForm.Fields.PhoneNumberThird
        formatPlaceholder={formatPlaceholder}
        className={styles.FieldInputItem}
      />
      <Styled.PhoneNumberSuffix>
        {formatSuffix('PhoneNumber')}
      </Styled.PhoneNumberSuffix>
    </Styled.PhoneNumberContainer>
  )
})
