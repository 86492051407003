import React from 'react'

import { Provider as ButtonStyleProvider } from '../Buttons/Style/Provider'
import { Provider as InputStyleProvider } from '../Input/Style/Provider'
import { Provider as FieldStyleProvider } from '../Field/Style/Provider'
import { Provider as ErrorStyleProvider } from '../Errors/Style/Provider'

/**
 * Compound Style Provider to provide style at once
 */
export const Provider = ({ children, styles }) => (
  <InputStyleProvider styles={styles}>
    <FieldStyleProvider styles={styles}>
      <ButtonStyleProvider styles={styles}>
        <ErrorStyleProvider styles={styles}>{children}</ErrorStyleProvider>
      </ButtonStyleProvider>
    </FieldStyleProvider>
  </InputStyleProvider>
)
