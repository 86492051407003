import React from 'react'

import { withDefaultRenderComponent } from '@arch-log/webapp.atomics/components/Rules'

import * as ModuleNotices from '@arch-log/webapp.modules/notifier/components/Notices'
import * as Styled from './Styled'

export * from './Notice'

/**
 */
export const Notices = withDefaultRenderComponent(
  ({
    children,
    components: {
      Wrapper = Styled.NoticesWrapper,
      Item = Styled.NoticesItem,
    } = {},
  }) => {
    return (
      <Wrapper>
        <ModuleNotices.Each
          render={({ notice }) => {
            return <Item level={notice?.level}>{children}</Item>
          }}
        />
      </Wrapper>
    )
  },
)
