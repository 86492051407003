import React from 'react'

import * as styles from './styles.module.scss'
import { classNames } from '@arch-log/webapp.lib/CssHelper'
import * as Levels from '@arch-log/webapp.modules/notifier/Levels'

/**
 */
export const NoticesWrapper = ({ children }) => (
  <div className={styles.NoticesWrapper_Outer}>
    <ul className={styles.NoticesWrapper}>{children}</ul>
  </div>
)

/**
 */
export const NoticesItem = ({ children, level = Levels.Info }) => {
  return (
    <li
      className={classNames([
        styles.NoticesItem,
        styles[`NoticesItem_${Levels.toString(level)}`],
      ])}
    >
      {children}
    </li>
  )
}

/**
 */
export const NoticeWrapper = ({ children }) => (
  <div className={styles.NoticeWrapper}>{children}</div>
)

/**
 */
export const NoticeRemove = ({ children = 'x', onRemove }) => (
  <span className={styles.NoticeRemove} onClick={onRemove}>
    {children}
  </span>
)

/**
 */
export const NoticeMessage = ({ children }) => (
  <span className={styles.NoticeMessage}>{children}</span>
)
