import React from 'react'

import * as FormButtons from '@arch-log/webapp.atomics/components/Form/Buttons'
import * as styles from './styles.module.scss'

/**
 */
export const Title = ({ children }) => (
  <span className={styles.Title}> {children}</span>
)

/**
 */
export const FieldContainer = ({ children }) => (
  <dl className={styles.FieldContainer}>{children}</dl>
)

/**
 */
export const FieldLabel = ({ children }) => (
  <dt className={styles.FieldLabel}>{children}</dt>
)

/**
 */
export const FieldInput = ({ children }) => <dd>{children}</dd>

/**
 */
export const ButtonContainer = ({ children }) => (
  <div className={styles.ButtonContainer}>{children}</div>
)

/**
 */
export const SubmitButton = ({ children }) => (
  <FormButtons.Submit className={styles.SubmitButton}>
    {children}
  </FormButtons.Submit>
)

/**
 */
export const InputRequiredLabel = ({ children }) => {
  return <div className={styles.InputRequiredLabel}>{children}</div>
}

/**
 */
export const RequiredIcon = ({ required }) => (
  <span className={styles.RequiredIcon}>{required ? '*' : null}</span>
)

/**
 */
export const PostalCodeSeparator = ({ children = ' - ' }) => <>{children}</>

/**
 */
export const PhoneNumberContainer = ({ children, props }) => (
  <div className={styles.PhoneNumberContainer} {...props}>
    {children}
  </div>
)

/**
 */
export const PhoneNumberSeparator = ({ children = ' - ', props }) => (
  <p className={styles.PhoneNumberSeparator} {...props}>
    {children}
  </p>
)

/**
 */
export const PhoneNumberSuffix = ({ children }) => (
  <p className={styles.PhoneNumber_Suffix}>{children}</p>
)
