import React from 'react'

import { Context } from './Context'

import * as DefaultStyles from './default.module.scss'

/**
 */
export const Provider = ({ children, styles = DefaultStyles }) => {
  return (
    <Context.Provider value={{ styles: { ...DefaultStyles, ...styles } }}>
      {children}
    </Context.Provider>
  )
}
