import React from 'react'

/**
 *
 */
import * as DefaultStyles from './default.module.scss'

/**
 *
 */
export const Context = React.createContext({
  styles: DefaultStyles,
})
