import React from 'react'

import {
  useTranslation,
  translated,
} from '@arch-log/webapp.lib/TranslationHelper'

/**
 */
export const RequiredLabel = translated('app.project')(
  'AddressNewPage.RequiredLabel',
)

/**
 */
export const Title = translated('app.project')('AddressNewPage.Title')

/**
 */
export const Submit = translated('app.project')('AddressNewPage.Submit')

/**
 */
export const Cancel = translated('app.project')('AddressNewPage.Cancel')

/**
 */
export const ResolveAddress = translated('app.project')(
  'AddressNewPage.ResolveAddress',
)

/**
 */
export const Progress = translated('common')('Progress')

/**
 */
export const useLiterals = () => {
  const { t } = useTranslation(['module.address', 'module.project.destination'])

  const formatFormError = (key, ...options) => {
    return t(`module.project.destination:${key}`, ...options)
  }

  const formatFieldLabel = (field, ...options) => {
    return t(
      [
        `module.address:Field.Labels.${field}`,
        `module.project.destination:Field.Labels.${field}`,
      ],
      ...options,
    )
  }

  const formatPlaceholder = (key, ...options) => {
    return t(
      [
        `module.address:Field.Placeholder.${key}`,
        `module.project.destination:Field.Placeholder.${key}`,
      ],
      ...options,
    )
  }

  const formatAction = (key, ...options) => {
    return t(`module.address:Action.${key}`, ...options)
  }

  const formatActionError = (key, ...options) => {
    return t(`module.address:Action.Error.${key}`, ...options)
  }

  const formatFieldError = (key, ...options) => {
    return t(`module.project.destination:Form.Error.${key}`, ...options)
  }

  const formatSuffix = (key, ...options) => {
    return t(`module.project.destination:Field.Suffix.${key}`, ...options)
  }

  return {
    formatAction,
    formatFormError,
    formatFieldLabel,
    formatPlaceholder,
    formatActionError,
    formatFieldError,
    formatSuffix,
  }
}
